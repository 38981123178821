.mainWrapper {
  padding-top: 131px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
  color: #29323d;
}

.titleUser {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #101010;
  margin-bottom: 27px;
}
